@import "~@blueprintjs/core/lib/scss/variables.scss";

body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.card-dashboard {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    width: 180px;
    display: inline-block;
    margin: 28px;
    border-radius: 20px;
}

.card-inner {
    text-align: center;
    color: #5C7080;
    background-color: #F5F8FA;
    border-radius: 15px;
}

.card-global {
    width: 500px;
    display: inline-block;
    margin: 30px;
}

.card-messages {
    width: 720px;
    display: inline-block;
    margin: 30px;
}

.cab-card {
    width: 600px;
    display: inline-block;
    margin: 30px;
}

.card-global-inner {
    color: #5C7080;
    background-color: #F5F8FA;
    border-radius: 10px;
}

.navbar {
    height: 56px;
    box-shadow: rgba(12, 52, 75, .15) 0px 3px 3px;
    -webkit-box-pack: justify;
}



.sidebar-menu {
    height: 100%;
    width: 160px;
    position: fixed;
    z-index: 1;
    padding-top: 20px;
}

.main-content {
    margin-left: 260px;
    padding-top: 20px;
}

.form-content {
    margin-left: 220px;
    padding-top: 20px;
    width: 460px;
}

.master-title {
    padding-top: 20px;
    margin-left: auto;
    font-size: 22px;
}

.back-icon {
    float: left;
    margin-left: auto;
    cursor: pointer;
    display: block;
    width: 1.3em !important;
    height: 1.3em !important;
    border-radius: 50%;
    border: 1px solid rgb(206, 190, 190);
}

.stepper-content {
    margin-left: 20px;
    padding-top: 20px;
}

.form-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-guests {
    width: 95%;
    display: inline-block;
    margin: 30px;
    border-radius: 20px;
}

.stepper-btn {
    text-align: right;
}

.form-groups {
    display: inline-flex;
}

.bp3-form-group { 
    padding-right: 25px;
}

.formControl {
    min-width: 200px;
    max-width: 350px;
}

.switchControl {
    min-width: 150px;
    max-width: 300px;
}

.switch-groups {
    display: inline-flex;
}

.iconButton {
    margin-right: "24px";
    top: "50%";
    display: "inline-block";
    position: "relative";
  }

.families {
    margin: 30px;
}

.action-icons {
    float: right;
    padding-top: 0 !important;
}

.guest-name {
    cursor: pointer;
    color: #106ba3;
    font-weight: 600;
}

.form-selects {
    display: inline-flex;
}

.select-control {
    min-width: 200px;
    max-width: 300px;
}